import crypto from '@/utils/crypto.js';

export default{
    jiami(data){
        //获取当前时间戳13位 + 3位字符
        var timestamp = new Date().getTime().toString() + "WZH";//密码
        //加密密钥16位
        // var encrypt_key = timestamp;
        var encrypt_key = '1635230227540WZH';//长度必须为16位 
        // console.log(encrypt_key);
        //加密向量16位
        var iv = 'ZZWBKJ_ZHIHUAWEI';
        let obj = JSON.stringify(data)
        // console.log('obj',obj);
        //要加密的数据
        var encrypt_string = obj;
        var encrypted_string = crypto.encrypt(encrypt_string, encrypt_key, iv);
        // console.log('加密',encrypted_string);
        return encrypted_string
    },
    jiemi(data){
        //获取当前时间戳13位 + 3位字符
        var timestamp = new Date().getTime().toString() + "WZH";//密码
        //加密密钥16位
        // var encrypt_key = timestamp;
        var encrypt_key = '1635230227540WZH';//长度必须为16位 
        // console.log(encrypt_key);
        //加密向量16位
        var iv = 'ZZWBKJ_ZHIHUAWEI';
        // 解密
        try{
            var decrypted_string = crypto.decrypt(data, encrypt_key, iv);
            // console.log('解密',decrypted_string);//{"username":"1001","password":"123456","terminal":"PC"}
        }catch(exception){//密码错误
            // console.log(exception.message);//Malformed UTF-8 data
        }
    }
}