const state = {
    device: 'pc' // 默认是PC端，====》 PC端：pc、移动端：m
}

const mutations = {
    SET_DEVICE: (state, device) => {
        state.device = device
    }
}

const actions = {
    setDevice({ commit }, device) {
        commit('SET_DEVICE', device)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}