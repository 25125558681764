<template>
  <div id="app" :class="[device]">
    <keep-alive>
      <router-view />
    </keep-alive>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { throttle } from '@/utils/index.js'
export default {
  computed: {
    ...mapGetters(['device'])
  },
  created() {
    // resize节流
    this.resizeChange = throttle(this.resizeChange, 200)
    this.resizeChange();
    window.addEventListener('resize', this.resizeChange, false)
    if (window.performance.navigation.type == 1) {
      console.log("页面被刷新");
      localStorage.setItem('glag','1')
    }else{
      console.log("首次被加载");
    }
  },
  methods: {
    resizeChange() {
      // console.log('宽度',document.documentElement.clientWidth)
      if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
        this.$store.dispatch('app/setDevice', 'mobile')
      } else {
        this.$store.dispatch('app/setDevice', 'pc')
      }
    },
  },
  destroyed() {
    window.removeEventListener('resize', this.resizeChange, false)
  }
}
</script>

<style lang="less">
*{
  margin: 0;
  padding: 0;
  border: 0;
}
.pointer {
  cursor: pointer;
}

</style>
