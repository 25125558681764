
const zhTW = {
    lang: 'zh_CN',
    hongke: '中國紅客',
    hongkeAuthority: '中國紅客官方網站',
    Chinese: '中文CN',
    English: '英文EN',
    ComplexForm: '繁體',
    index: '首頁',
    news: '紅客新聞',
    events: '紅客活動',
    securityInfo: '安全資訊',
    internationalInfo: '國際資訊',
    introduce: '紅客介紹',
    product: '紅客產品',
    contact: '聯繫我們',
    more: '更多',
    moreContent: '顯示更多相關內容',
    corporateValues: '企業價值觀',
    enterpriseMission: '企業使命',
    corporateVision: '企業願景',
    extendTitleLeft: '@中國紅客',
    extendTitleRight: '我來說',
    inherit: '紅客精神的傳承者',
    escort: '數位中國的護航者',
    extend: '網路安全的推廣者',
    join: '我想加入',
    typeSize: '字号',
    acquiesce: '默認',
    big: '大',
    oversized: '超大',
    print: '打印',
    timeSort: '按發稿時間排序',
    name: '請輸入姓名',
    phone: '請輸入手機號',
    mail: '請輸入郵箱',
    submit: '提交',
    friendlySupport: '中國紅客組織友情支持',
    relatedLink: '相關鏈接',
    aboutUs: '關於我們',
    search: '蒐索列表',
    noSearchArticles: '暫無搜索文章，請重新搜索',
    wechat: '微信公眾號：',
    trill: '抖音號：',
    mailbox: '郵箱：',
    technicalSupport: '技術支持',
    yiLanYun: '依藍雲',
}
export default zhTW;