const en = {
    lang: 'en_US',
    hongke: 'China Hongke',
    hongkeAuthority: 'China red guest official website',
    Chinese: 'Chinese',
    English: 'English',
    ComplexForm: 'ComplexForm',
    index: 'home page',
    news: 'news',
    events: 'events',
    securityInfo: 'security info',
    internationalInfo: 'international info',
    introduce: 'introduce',
    product: 'product',
    contact: 'contact us',
    more: 'more',
    moreContent: 'Show more related content',
    corporateValues: 'Corporate values',
    enterpriseMission: 'Enterprise mission',
    corporateVision: 'Corporate vision',
    extendTitleLeft: '@China Hongke',
    extendTitleRight: 'It is me',
    inherit: 'The inheritor of the spirit of Hongke',
    escort: 'Escorts of Digital China',
    extend: 'A promoter of cyber security',
    join: 'I want to join',
    typeSize: 'Type size',
    acquiesce: 'acquiesce',
    big: 'big',
    oversized: 'oversized',
    print: 'print',
    timeSort: 'Sort by time of publication',
    name: 'Please enter your name',
    phone: 'Please enter your phone number',
    mail: 'Please enter email',
    submit: 'submit',
    friendlySupport: 'China Red guest organization friendship support',
    relatedLink: 'Related link',
    aboutUs: 'About us',
    search: 'Search list',
    noSearchArticles: 'No search articles, please search again',
    wechat: 'Wechat public account：',
    trill: 'Tik tok：',
    mailbox: 'mailbox：',
    technicalSupport: 'Technical support',
    yiLanYun: 'Yilanyun',
}
export default en;