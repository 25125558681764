import Vue from 'vue'
import VueRouter from 'vue-router'
import router_modular from '@/router/router_modular/index.js'

Vue.use(VueRouter)
//解决vue路由重复导航错误
//获取原型对象上的push函数
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}
const routes = [
	...router_modular
]

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, savedPosition) {
		// 判断是否有指定元素的锚点
		let str = localStorage.getItem('glag');
		// if (str == '2') {
		// 	if (to.hash) {
		// 		const anchor = document.querySelector(to.hash);
		// 		// console.log('anchor',anchor);
		// 		if (anchor) {
		// 			return {
		// 				selector: to.hash, //定位位置
		// 				behavior: 'smooth' // 平滑滚动
		// 			};
		// 		}
		// 	}

		// }
		// if (str == '2') {
			if (savedPosition) {
				return savedPosition
			}
		// }
		// 默认滚动到顶部
		return {
			x: 0,
			y: 0
		};
	}

})

//全局路由守卫
router.beforeEach((to, from, next) => {
	// console.log('beforeEach',to,from);
	next()
})
router.afterEach((to, from) => {
	// console.log('afterEach',to,from);
})

export default router