
const zh = {
    lang: 'zh_CN',
    hongke: '中国红客',
    hongkeAuthority: '中国红客官方网站',
    Chinese: '中文CN',
    English: '英文EN',
    ComplexForm: '繁体',
    index: '首页',
    news: '红客新闻',
    events: '红客活动',
    securityInfo: '安全资讯',
    internationalInfo: '国际资讯',
    introduce: '红客介绍',
    product: '红客产品',
    contact: '联系我们',
    more: '更多',
    moreContent: '显示更多相关内容',
    corporateValues: '企业价值观',
    enterpriseMission: '企业使命',
    corporateVision: '企业愿景',
    extendTitleLeft: '@中国红客',
    extendTitleRight: '我来说',
    inherit: '红客精神的传承者',
    escort: '数字中国的护航者',
    extend: '网络安全的推广者',
    join: '我想加入',
    typeSize: '字号',
    acquiesce: '默认',
    big: '大',
    oversized: '超大',
    print: '打印',
    timeSort: '按发稿时间排序',
    name: '请输入姓名',
    phone: '请输入手机号',
    mail: '请输入邮箱',
    submit: '提交',
    friendlySupport: '中国红客组织友情支持',
    relatedLink: '相关链接',
    aboutUs: '关于我们',
    search: '搜索列表',
    noSearchArticles: '暂无搜索文章，请重新搜索',
    wechat: '微信公众号：',
    trill: '抖音号：',
    mailbox: '邮箱：',
    technicalSupport: '技术支持',
    yiLanYun: '依蓝云',
}
export default zh;