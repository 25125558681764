const router_modular = [
	{
		path: '/',
		// name: 'Index',
		component: () => import(/* webpackChunkName: "about" */ '@/views/index'),
		children:[
			{
				path: '/',
				name: 'Content',
				component: () => import('@/views/index/content'),
			},
			{
				path: '/news',
				name: 'News',
				component: () => import(/* webpackChunkName: "about" */ '@/views/index/news')
			},
			{
				path: '/detail',
				name: 'Detail',
				component: () => import(/* webpackChunkName: "about" */ '@/views/index/detail'),
			},
			{
				path: '/construct',
				name: 'Construct',
				component: () => import(/* webpackChunkName: "about" */ '@/views/index/construct'),
			},
		]
	},
	{
		path: '/toweChat',
		name: 'toweChat',
		component: () => import(/* webpackChunkName: "about" */ '@/views/toweChat/index')
	},
]
export default router_modular
