import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import 'amfe-flexible'
import i18n from './lang/index.js';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Vant from 'vant';
import 'vant/lib/index.css';
import md5 from 'js-md5'
import miwen from './api/jiami.js'
import Print from 'vue-print-nb'

Vue.use(Print)
Vue.use(Vant);
Vue.prototype.$md5 = md5;
Vue.prototype.$axios = axios
Vue.prototype.$miwen = miwen
Vue.config.productionTip = false

Vue.use(ElementUI, {
  size: 'medium', // set element-ui default size
  i18n: (key, value) => i18n.t(key, value)
});

new Vue({
  router,
  store,
  axios,
  i18n,
  render: h => h(App)
}).$mount('#app')
